#responsive-carousel {
    width: 100%;
    display: flex;
    flex-direction: column;
}

#responsive-carousel-control {
    margin: 10px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#responsive-carousel-control button {
    all: unset;
}

#responsive-carousel-control img {
    margin: 0px 10px;
}

#responsive-carousel-control #indicator {
    height: 10px;
    width: 10px;
}