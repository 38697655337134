#training-entry-form {
    width: 100%;
    background-color: white;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    font-size: 25px;
}

#training-entry-form form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: 40px auto;
}

#training-entry-form form input {
    border: 1px solid #C9C9C9;
    border-radius: 5px;
    width: 100%;
    text-align: left;
    font-size: 18px;
}

#training-entry-form-row {
    display: flex;
    flex-direction: row;
    margin: 10px 86px;
    width: 100%;
    margin-bottom: 30px;
}

#training-entry-form-row #column {
    display: flex;
    width: 50%;
}

#training-entry-form-row #column input[type=date] {
    min-width: calc(100% - 20px);
    text-align: left;
    padding-left: 10px;
}

#training-entry-form-row #column input[type=time] {
    min-width: calc(100% - 20px);
    text-align: left;
    padding-left: 10px;
}

#training-entry-form-row #column select {
    width: 100%;
    font-size: 18px;
}

#training-entry-form-row #column select + select {
    margin-left: 10px;
}

#text-areas {
    margin-top: 20px;
    width: 100%;
}

#training-entry-form #buttons {
    width: 100%;
    display: flex;
    justify-content: space-around;
    
}

#training-entry-form #buttons #cancel {
    background-color: #CA0813;
}