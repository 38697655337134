.world-wide-panel #content-panel #body{
    padding: 0px 20px;
}

.world-wide-panel .line-stat {
    width: 100%;
    text-align: center;
    font-size: 22px;
    padding: 30px 0px;
}

.world-wide-panel .top-five-panel {
    width: 260px;
    text-align: center;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 20px 5px;
    flex-basis: calc(25% - 40px);
    overflow: hidden;
    background-color: #f5f5f5;
    text-align: left;
}

.world-wide-panel .top-five-panel .title {
    padding: 10px 0px;
    flex: 1 1 auto;
    font-size: 24px;
    width: 100%;
    font-size: 22px;
    font-weight: 500;
    margin: 0 auto;
    display: block;
    text-align: center;
}

.world-wide-panel .top-five-panel .content {
    padding: 10px 0px;
}

.world-wide-panel .top-five-panel .content .top-five-row {
    display: flex;
    justify-content: space-evenly;
    line-height: 28px;
    font-size: 20px;
    font-weight: 400;
}

.world-wide-panel .top-five-panel .content .top-five-row .number {
    width: 10%;
    text-align: center;    
}

.world-wide-panel .top-five-panel .content .top-five-row .value {
    text-align: right;
    width: 50%;
}

#top-five h2 {
    text-align: center;
    width: 100%;
    font-size: 40px;
    margin: 0px;
}

@media only screen and (max-width: 1000px) {
    .world-wide-panel .top-five-panel {
        flex-basis: calc(40% - 40px);
    }
}

@media only screen and (max-width: 600px) {
    .world-wide-panel .top-five-panel {
        flex-basis: calc(80% - 40px);
    }
}