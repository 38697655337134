#log-table {
    width: 100%;
    overflow: hidden;
    border-collapse: collapse;
    /* TODO: Fix weird border space in log table caused by above
    Perhapse do not use a table anymore */
}

#log-table tr.log-row {
    height: 57px;
    font-weight: 600;
    text-decoration: none;
    color: black;
    font-size: 18px;
    cursor: pointer;
}

#no-entries {
    height: 100px;
    line-height: 100px;
    font-size: 18px;
    color: blue;
    text-align: center;
}

#no-entries a {
    text-decoration: none;
    color: blue;
}

#log-table tr.log-row td button {
    all: unset;
    font-size: 20px;
}

/* Offset first table cell to give it a more balanced look */
#log-table tr.log-row td:nth-child(1) {
    text-align: center;
}

/* TODO: Implement popup menu */
#log-table tr.log-row .popup-content {
    border-radius: 5px;
    background-color: red;
}

/* Add margin between How Felt label and value */
#log-table tbody tr#training-log-row td.how-felt span:nth-child(1) {
    margin-right: 5px;
}

/* Color how felt */
#log-table tbody tr#training-log-row td.how-felt .Great {
    color: #3158D0;
}

#log-table tbody tr#training-log-row td.how-felt .Good {
    color: #1C7248;
}

#log-table tbody tr#training-log-row td.how-felt .Okay {
    color: #FCD033;
}

#log-table tbody tr#training-log-row td.how-felt .Bad {
    color: #d42c44;
}

/* Color every other row */
#log-table tbody tr.log-row:nth-child(odd) {
    background: white;
}
#log-table tbody tr.log-row:nth-child(even) {
    background: #C9C9C9;
}

/* Override a few Popup Menu settings */
.log-row #popup-menu .popup-content {
    border-radius: 10px !important;
}

#popup-menu .popup-content .menu {
    width: 100%;
}

.log-row #popup-menu .menu #edit a {
    color: blue;
    font-weight: 400;
}

.log-row #popup-menu .menu #delete button {
    color: red;
    font-weight: 400;
}

.log-row #popup-menu .model .content {
    margin: 20px;
    line-height: 1.4em;
}

.log-row #popup-menu .model .actions {
    display: flex;
    justify-content: space-around;
    margin: 20px;
}

.log-row #popup-menu .model .actions #cancel {
    color: blue;
}

/* Hide columns on smaller devices */
@media only screen and (max-width: 1000px) {
    #log-table tbody tr#training-log-row td.professor-name {
        display: none;
    }
    #log-table tbody tr#training-log-row td.how-felt {
        display: none;
    }
    #log-table tbody tr#training-log-row td.sleep {
        display: none;
    }
    #log-table tbody tr#promotion-log-row td.professor-name {
        display: none;
    }
}

/* Hide even more columns on even smaller devices */
@media only screen and (max-width: 500px) {
    #log-table tbody tr#training-log-row td.location {
        display: none;
    }
    #log-table tbody tr#competition-log-row td.location {
        display: none;
    }
    #log-table tbody tr#promotion-log-row td.location {
        display: none;
    }
}