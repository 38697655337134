@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,700");

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.drop-shadow {
  border-top: 1px solid rgba(0, 0, 0, 0.10);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
}

/* Make space for drop shadow */
.slider-slide {
  padding: 6px 0; 
}

/* Make space between carousel and controller */
.slider {
  margin: 20px 0;
}

.corner-radius {
  border-radius: 15px;
  overflow: hidden;
}