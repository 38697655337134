.account {
  /* Style for "Background" */
  width: 300px;
  background-color: #bbbbbb;
  margin: 25px auto;
  padding: 10px 0px;
  border-radius: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
}
  
.account img {
  /* Style for "BJJ logboo" */
  width: 126px;
  height: 126px;
  margin: 0px auto;
  padding: 25px;
  display: block;
}

.account h1 {
  /* Style for "BJJ Logboo" */
  width: 300px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -2.28px;
  margin: 0 auto;
  padding: 10px 5px;
}

.account h2 {
  /* Style for "Sign-in" */
  color: #333333;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  margin: 0 auto;
  padding: 10px 5px;
}

.account .input-row {
  display: flex;
  padding: 10px 4px;
}

.account input {
  height: 36px;
  border-radius: 5px;
  background-color: #ffffff;
  flex-grow: 100;
  margin: 0px 3px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  width: 50%;
}

.account ::placeholder {
  height: 23px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.account button {
  width: 280px;
  height: 36px;
  color: #ffffff;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  background-color:#1069c9;
  border-radius: 8px;
  margin: 20px auto;
  border: 0px solid black;
}

.account button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.account a {
  width: 256px;
  height: 23px;
  color: #0066cc;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
}

.account a.forgot-password {
  color: #333333;
  font-size: 18px;
  font-weight: 400;
}

.account p.error {
  color: #cf000c;
  font-weight: 600;
  font-size: 16px;
}

.account p.status {
  color: #1069c9 ;
  font-weight: 600;
  font-size: 16px;
}