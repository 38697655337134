@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
body {
  background-color: #c9c9c9;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;    
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.status {
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    padding: 15px 5px;
    text-align: center;
}

.status.error {
    color: #cf000c;
}
  
.status.success {
    color: #1069c9 ;
}

.status.none {
    /* Need to reserve space now because .slider-frame is set to overflow none */
    min-height: 22px;
}
.account {
  /* Style for "Background" */
  width: 300px;
  background-color: #bbbbbb;
  margin: 25px auto;
  padding: 10px 0px;
  border-radius: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
}
  
.account img {
  /* Style for "BJJ logboo" */
  width: 126px;
  height: 126px;
  margin: 0px auto;
  padding: 25px;
  display: block;
}

.account h1 {
  /* Style for "BJJ Logboo" */
  width: 300px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -2.28px;
  margin: 0 auto;
  padding: 10px 5px;
}

.account h2 {
  /* Style for "Sign-in" */
  color: #333333;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  margin: 0 auto;
  padding: 10px 5px;
}

.account .input-row {
  display: flex;
  padding: 10px 4px;
}

.account input {
  height: 36px;
  border-radius: 5px;
  background-color: #ffffff;
  flex-grow: 100;
  margin: 0px 3px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  width: 50%;
}

.account ::-webkit-input-placeholder {
  height: 23px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.account ::-moz-placeholder {
  height: 23px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.account :-ms-input-placeholder {
  height: 23px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.account ::-ms-input-placeholder {
  height: 23px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.account ::placeholder {
  height: 23px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.account button {
  width: 280px;
  height: 36px;
  color: #ffffff;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  background-color:#1069c9;
  border-radius: 8px;
  margin: 20px auto;
  border: 0px solid black;
}

.account button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.account a {
  width: 256px;
  height: 23px;
  color: #0066cc;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
}

.account a.forgot-password {
  color: #333333;
  font-size: 18px;
  font-weight: 400;
}

.account p.error {
  color: #cf000c;
  font-weight: 600;
  font-size: 16px;
}

.account p.status {
  color: #1069c9 ;
  font-weight: 600;
  font-size: 16px;
}
#content-panel {
    margin: auto;
    width: calc(100% - 6px);    /* Make room for drop shadow */
    flex: 1 1;
    margin-bottom: 20px;
}

#content-panel #header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: black;
    color: white;
    height: 100px;
    width: 100%;
    overflow: hidden;
}

#content-panel #header #title img {
    height: 74px;
    width: auto;
}

#content-panel #header #title {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: auto;
    height: 100%;
    font-size: 26px;
}

#content-panel #header button {
    all: unset;
    font-size: 17px;
    font-weight: 500;
    color: #1069c9;
    text-decoration: underline;
}

#content-panel #header button#left {
    margin-left: 20px;
}

#content-panel #header button#right {
    margin-right: 20px;
}

#content-panel #header button.hidden {
    visibility: hidden;
}

#content-panel #body {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background-color: white;
}

@media only screen and (max-width: 375px /* iPhone X */) {
    #content-panel {
        /* Subtract gutter space */
        width: calc(100% - 20px);
    }
    #content-panel #header #title {
        font-size: 18px;
    }
}
#responsive-carousel {
    width: 100%;
    display: flex;
    flex-direction: column;
}

#responsive-carousel-control {
    margin: 10px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#responsive-carousel-control button {
    all: unset;
}

#responsive-carousel-control img {
    margin: 0px 10px;
}

#responsive-carousel-control #indicator {
    height: 10px;
    width: 10px;
}
#sidebar .section {
    padding: 0px 30px;
    display: flex;
    flex-direction: column;
    align-content: left;
    align-items: left;
}

#sidebar .section h1 {
    text-align: left;
    margin-bottom: 20px;
}

#sidebar .section .content {
    width: 100%;
    display: flex;
    align-content: center;
    align-items: center;
}

#sidebar #close-section.section {
    display: flex !important;
    flex-direction: row-reverse;
}

#sidebar #close-section #close-button {
    all: unset;
    height: 50px;
    width: 50px;
    padding: 10px 0;
}

#sidebar #search-section form {
    display: flex;
    align-items: stretch;
    padding: 10px 0;
}

#sidebar #search-section form #search-icon {
    height: 50px;
    width: 50px;
}

#sidebar #search-section form input {
    font-size: 30px;
    border-radius: 10px;
    border: none;
    width: 100%;
    margin-left: 15px;
    text-align: center;
}

#sidebar #search-section form ::-webkit-input-placeholder {
    height: 100%;
    font-size: 26px;
    text-transform: uppercase;
    text-align: center;
    color: #c9c9c9;
}

#sidebar #search-section form ::-moz-placeholder {
    height: 100%;
    font-size: 26px;
    text-transform: uppercase;
    text-align: center;
    color: #c9c9c9;
}

#sidebar #search-section form :-ms-input-placeholder {
    height: 100%;
    font-size: 26px;
    text-transform: uppercase;
    text-align: center;
    color: #c9c9c9;
}

#sidebar #search-section form ::-ms-input-placeholder {
    height: 100%;
    font-size: 26px;
    text-transform: uppercase;
    text-align: center;
    color: #c9c9c9;
}

#sidebar #search-section form ::placeholder {
    height: 100%;
    font-size: 26px;
    text-transform: uppercase;
    text-align: center;
    color: #c9c9c9;
}

#sidebar .section .content div img {
    margin-left: 20px;
    margin-right: 30px;
}

.section .content #menu-link {
    all: unset;
    color: white;
    -webkit-text-fill-color: white;
    font-size: 22px;
    font-weight: 400;
    text-decoration: none;
    display: block;
    padding: 5px 0px;
    cursor: default;
    text-align: left;
}

.bm-menu {
    background-color: #CC0000;
    color: white;
    margin: 0;
}

.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 15px;
    top: 25px;
}

@media only screen and (max-width: 414px) {
    #sidebar .section {
        padding: 10px 20px;
    }
    #sidebar .section .content div img {
        margin-left: 10px;
        margin-right: 20px;
    }
    .section .content #menu-link {
        padding: 5px 0px;
    }
}

@media only screen and (max-width: 320px) {
    #sidebar .section h1 {
        margin-top: 5px;
        margin-bottom: 5px;
    }
    #sidebar .section .content #menu-link {
        font-size: 18px;
    }
}
#page > #header {
    flex-shrink: 0;
    height: 55px !important;
    width: 100%;
    background-color: black;
    display: flex;
    justify-content: space-around;
}

#page > #header .button-area {
    flex-grow: 1;
    flex-basis: 10px;
    width: 10px;
}

#page > #header #logo-area {
    display: flex;
}

#page > #header #logo-area a {
    text-decoration: none;
}

#page > #header #logo-area a #logo {
    width: 228px;
    height: 100%;
    display: flex;
    background-color: #CC0000;
}

#page > #header #logo-area a #logo .white {
    width: 6px;
    height: 100%;
    background-color: white;
}

#page > #header #logo-area a #logo #title {
    flex-grow: 1;
    text-align: center;
    color: white;
    font-size: 30px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
}

#page > #header .button-area#right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

#page > #header .button-area#right button {
    all: unset;
    margin-right: 10px;
    width: 44px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#page > #header .button-area#right button img {
    width: 100%;
}

@media only screen and (max-width: 375px) {
    #page > #header #logo-area a #logo {
        width: 200px;
    }
    #page > #header #logo-area a #logo #title {
        font-size: 30px;
        font-weight: 600;
    }
    #page > #header .button-area#right {
        justify-content: center;
    }
    #page > #header .button-area#right button {
        margin-right: 0px;
        width: 32px;
    }
}
#belt {
    position: relative;
    border: 1px solid black;
    border-radius: 10px;
    display: flex;
    justify-items: center;
    overflow: hidden;
}

#belt #background {
    width: 100%;
    height: 100%;
}

#belt #background #decorations {
    height: 100%;
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-between;
}

#belt #kid-stripe {
    position: absolute;
    width: 100%;
    top: 12px;
    bottom: 12px;
    z-index: 0;
}

#belt #belt-text {
    font-size: 20px;
    line-height: 50px;
    font-weight: 600;
    z-index: 1;
    text-align: center;
    flex: 1 1;
}

#belt #rank {
    display: flex;
    flex-direction: row-reverse;
    z-index: 1;
    width: 70px;
}

#belt #rank #stripe {
    background-color: white;
    width: 11%;
    margin-right: 11%;
    height: 100%;
    z-index: 1;
}
#header-stats {
    margin: 20px auto;
    width: 100%;
    display: flex;
}

#header-stats .column {
    width: 280px;
    height: 100px;
    margin: auto;
    outline: none;
    overflow: none;
}

#user-info-panel {
    display: flex;
    border-radius: 10px;
}   

#user-info-panel #left-column {
    display: flex;
    align-items: center;
    flex-grow: 0;
}

#user-info-panel #left-column #logo {
    width: auto;
}

#user-info-panel #right-column {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

#user-info-panel #right-column div + div {
    margin-top: 10px;
}

#user-info-panel #right-column #user-name {
    font-size: 25px;
    font-weight: 700;
}

#header-belt-panel #belt {
    height: 100%;
    width: 100%;
    border: none;
}

#header-belt-panel #belt #belt-background #kid-stripe {
    height: 50%;
    margin-top: auto;
    margin-bottom: auto;
}

#header-belt-panel #belt #rank {
    width: 40%;
}

#total-training-panel  {
    height: 100px;
    width: 300px;
    border-radius: 10px;
    text-align: center;
    overflow: hidden;
}

#total-training-panel #title-row {
    height: 40%;
    display: flex;
    flex-direction: column;
}

#total-training-panel #title {
    background: #cc0000;
    color: white;
    font-size: 150%;
    font-weight: 600;
    height: 100%;
    width: 100%;
    padding-top: 2px;
}

#total-training-panel #duration-value-row {
    height: 30%;
    background-color: white;
    display: flex;
    justify-content: space-around;
}

#total-training-panel #duration-value-row .duration-value {
    font-size: 150%;
    font-weight: 600;
    margin: auto;
}

#total-training-panel #duration-title-row {
    height: 30%;
    background-color: white;
    display: flex;
    justify-content: space-around;
}

#total-training-panel #duration-title-row .duration-title {
    background-color: black;
    color: white;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 600;
    width: 85px;
    height: 70%;
    height: 16px;
    line-height: 16px;
    margin: auto;
}

#header-stat-panel {
    height: 100px;
    width: 300px;
    border-radius: 10px;
    text-align: center;
    overflow: hidden;
}

#header-stat-panel #title-row {
    height: 40%;
    display: flex;
    flex-direction: column;
}

#header-stat-panel #title {
    background: black;
    color: white;
    font-size: 150%;
    font-weight: 600;
    height: 100%;
    width: 100%;
    padding-top: 2px;
}

#header-stat-panel #body-row {
    height: 60%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
#header-stat-panel #body {
    width: 100%;
    background: white;
    color: black;
    font-size: 250%;
    font-weight: 700;
    height: 100%;
    padding-top: 2px;
}

@media only screen and (max-width: 375px /* iPhone X */) {
    #header-stats {
        margin: 5px auto;
    }
    #header-stats .column {
        /* Subtract gutter space */
        width: calc(100% - 20px);
        height: 80px;
    }
    #user-info-panel #left-column #logo {
        height: 80px;
    }
}
#popup-menu .menu {
    font-weight: 600;
    border-radius: 20px;
    max-width: 300px;
}

#popup-menu .menu .menu-item {
    margin-bottom: 5px;
    text-align: center;
}

#popup-menu .menu h1 {
    font-size: 24px;
    color: #707070;
}

#popup-menu .menu a {
    color: black;
    text-decoration: none;
    font-size: 20px;
    font-weight: 400;
}
#footer {
    /* TODO: Figure out why I cannot increase height without footer moving off page  */
    background-color: black;
    height: 55px;
    width: 100%;
    font-weight: 700;
    letter-spacing: -1.43px;
    overflow: hidden;
    flex-shrink: 0;
    
    /* Fix footer to bottom of screen (for iOS devices) */
    /* position: fixed;
    bottom: 0;
    z-index: 10; */
}

#footer #container {
  width: 70%;
  max-width: 1300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  font-family: "Open Sans";
  height: 100%;
  background-color: black;
  margin: auto;
  font-size: 18px;
}

#footer #container .popup-content {
  border-radius: 10px;
}

#footer #container .menu {
  font-weight: 600;
  border-radius: 15px;
  max-width: 300px;
  padding-bottom: 10px;
}

#footer #container .menu .menu-item {
  margin-bottom: 5px;
}

#footer #container .menu h1 {
  font-size: 24px;
  color: #707070;
  text-align: center;
}

#footer #container .menu a {
  color: black;
  text-decoration: none;
  font-size: 25px;
  font-weight: 400;
}

@media only screen and (max-width: 700px) {
  #footer #container {
    flex-direction: column;
    justify-content: space-around;
  }
  #footer #container #tagline {
    display: none;
  }
}
#page {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

#page.menu-open {
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-filter: blur(35px);
  -moz-filter: blur(5px);
  -ms-filter: blur(5px);
  -o-filter: blur(5px);
  filter: blur(5px);
}

#page-menu-wrapper {
  height: 100vh;
}

#page {
  display: flex;
  flex-direction: column;
  background-color: #F0F0F0;
}

#page #content {
  flex-grow: 1;
  width: 100%;
  overflow: auto;
  flex-direction: column;

  /* Fix footer to bottom of screen (for iOS devices) */
  /* position: fixed;
  top: 80px;
  bottom: 100px;
  z-index: 10; */
}

#page #page-body {
  margin: auto;
  width: 95%;
  max-width: 1300px;
  display: flex;
  flex-direction: column;
}
button {
    /* TODO: Move this */
    width: 240px;
    height: 36px;
    color: #ffffff;
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    background-color:#1069c9;
    border-radius: 8px;
    border: 0px solid black;
}

#home #content-panel #header {
    display: none;
}

#home #content-panel #body {
    border: none;
    box-shadow: none;
}

#home-page-panel {
    max-width: 280px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    margin: auto;
}

#home #content-panel #body {
    background-color: transparent;
}

#home-page-panel img {
    height: 150px;
    width: 150px;
    padding: 25px;
}

#home-page-panel .title {
    width: 100%;
    height: 40px;
    color: white;
    background-color: black;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: -1.43px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#home-page-panel .text {
    /* TODO: Change this to use flex instead of a fixed height */
    height: 140px;
    color: #000000;
    font-size: 22px;
    font-weight: 300;
    line-height: 32px;
    display: flex;
    justify-content: center;
    margin: 20px;
}

#home-page-panel button {
    text-transform: capitalize;
    margin: 20px;
    width: 80%;
    display: block;
}

@media only screen and (max-width: 375px /* iPhone X */) {
    #home-page-panel {
        /* Subtract gutter space to make room for drop shadow */
        width: calc(100% - 20px);
    }
}
#log-table {
    width: 100%;
    overflow: hidden;
    border-collapse: collapse;
    /* TODO: Fix weird border space in log table caused by above
    Perhapse do not use a table anymore */
}

#log-table tr.log-row {
    height: 57px;
    font-weight: 600;
    text-decoration: none;
    color: black;
    font-size: 18px;
    cursor: pointer;
}

#no-entries {
    height: 100px;
    line-height: 100px;
    font-size: 18px;
    color: blue;
    text-align: center;
}

#no-entries a {
    text-decoration: none;
    color: blue;
}

#log-table tr.log-row td button {
    all: unset;
    font-size: 20px;
}

/* Offset first table cell to give it a more balanced look */
#log-table tr.log-row td:nth-child(1) {
    text-align: center;
}

/* TODO: Implement popup menu */
#log-table tr.log-row .popup-content {
    border-radius: 5px;
    background-color: red;
}

/* Add margin between How Felt label and value */
#log-table tbody tr#training-log-row td.how-felt span:nth-child(1) {
    margin-right: 5px;
}

/* Color how felt */
#log-table tbody tr#training-log-row td.how-felt .Great {
    color: #3158D0;
}

#log-table tbody tr#training-log-row td.how-felt .Good {
    color: #1C7248;
}

#log-table tbody tr#training-log-row td.how-felt .Okay {
    color: #FCD033;
}

#log-table tbody tr#training-log-row td.how-felt .Bad {
    color: #d42c44;
}

/* Color every other row */
#log-table tbody tr.log-row:nth-child(odd) {
    background: white;
}
#log-table tbody tr.log-row:nth-child(even) {
    background: #C9C9C9;
}

/* Override a few Popup Menu settings */
.log-row #popup-menu .popup-content {
    border-radius: 10px !important;
}

#popup-menu .popup-content .menu {
    width: 100%;
}

.log-row #popup-menu .menu #edit a {
    color: blue;
    font-weight: 400;
}

.log-row #popup-menu .menu #delete button {
    color: red;
    font-weight: 400;
}

.log-row #popup-menu .model .content {
    margin: 20px;
    line-height: 1.4em;
}

.log-row #popup-menu .model .actions {
    display: flex;
    justify-content: space-around;
    margin: 20px;
}

.log-row #popup-menu .model .actions #cancel {
    color: blue;
}

/* Hide columns on smaller devices */
@media only screen and (max-width: 1000px) {
    #log-table tbody tr#training-log-row td.professor-name {
        display: none;
    }
    #log-table tbody tr#training-log-row td.how-felt {
        display: none;
    }
    #log-table tbody tr#training-log-row td.sleep {
        display: none;
    }
    #log-table tbody tr#promotion-log-row td.professor-name {
        display: none;
    }
}

/* Hide even more columns on even smaller devices */
@media only screen and (max-width: 500px) {
    #log-table tbody tr#training-log-row td.location {
        display: none;
    }
    #log-table tbody tr#competition-log-row td.location {
        display: none;
    }
    #log-table tbody tr#promotion-log-row td.location {
        display: none;
    }
}
#search-table #search-row .date {
    width: 140px;
}

#search-table #search-row .type {
    width: 120px;
}

#search-table #search-row .key {
    width: 30%;
}

#search-table #search-row .match {
    padding: 10px 20px; 
    font-weight: 200;
}

#search-table #search-row .menu {
    padding-right: 10px;
}

#search-table #popup-menu .popup-content .menu {
    width: 100%;
}

#search-table #popup-menu .popup-content .menu #edit {
    text-align: center;
}

#search-table #popup-menu .popup-content .menu hr {
    display: none;
}

#search-table #popup-menu .popup-content #delete {
    display: none;
}

@media only screen and (max-width: 600px) {
    #search-table #search-row .date {
        display: none;
    }
    #search-table #search-row .type {
        display: none;
    }    
    #search-table #search-row .key {
        display: none;
    }
}

@media only screen and (max-width: 1000px) {
    #search-table #search-row .type {
        display: none;
    }    
}

#logbook {
    width: 100%;
}

/* TODO: Figure out how to make parent resize to height of child (LogTable) */
#logbook #panel {
    height: 600px;
}

#time-duration {
    width: 100%;
    display: flex;
}

#time-duration span {
    width: 100%;
    display: flex;
    justify-content: center;
}

#text-area {
    width: 100%;
    height: 260px;
    display: flex;
    flex-direction: column;
    font-size: 25px;
    margin-top: 20px;
}

#text-area span {
    color: white;
    background-color: black;
    border-radius: 12px;
    text-align: center;
}

#text-area textarea {
    height: 100%;
    margin: 20px 0px;
    border-radius: 10px;
    border: 1px solid #C9C9C9;
    font-size: 18px;
}
#training-entry-form {
    width: 100%;
    background-color: white;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    font-size: 25px;
}

#training-entry-form form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: 40px auto;
}

#training-entry-form form input {
    border: 1px solid #C9C9C9;
    border-radius: 5px;
    width: 100%;
    text-align: left;
    font-size: 18px;
}

#training-entry-form-row {
    display: flex;
    flex-direction: row;
    margin: 10px 86px;
    width: 100%;
    margin-bottom: 30px;
}

#training-entry-form-row #column {
    display: flex;
    width: 50%;
}

#training-entry-form-row #column input[type=date] {
    min-width: calc(100% - 20px);
    text-align: left;
    padding-left: 10px;
}

#training-entry-form-row #column input[type=time] {
    min-width: calc(100% - 20px);
    text-align: left;
    padding-left: 10px;
}

#training-entry-form-row #column select {
    width: 100%;
    font-size: 18px;
}

#training-entry-form-row #column select + select {
    margin-left: 10px;
}

#text-areas {
    margin-top: 20px;
    width: 100%;
}

#training-entry-form #buttons {
    width: 100%;
    display: flex;
    justify-content: space-around;
    
}

#training-entry-form #buttons #cancel {
    background-color: #CA0813;
}


.world-wide-panel #content-panel #body{
    padding: 0px 20px;
}

.world-wide-panel .line-stat {
    width: 100%;
    text-align: center;
    font-size: 22px;
    padding: 30px 0px;
}

.world-wide-panel .top-five-panel {
    width: 260px;
    text-align: center;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 20px 5px;
    flex-basis: calc(25% - 40px);
    overflow: hidden;
    background-color: #f5f5f5;
    text-align: left;
}

.world-wide-panel .top-five-panel .title {
    padding: 10px 0px;
    flex: 1 1 auto;
    font-size: 24px;
    width: 100%;
    font-size: 22px;
    font-weight: 500;
    margin: 0 auto;
    display: block;
    text-align: center;
}

.world-wide-panel .top-five-panel .content {
    padding: 10px 0px;
}

.world-wide-panel .top-five-panel .content .top-five-row {
    display: flex;
    justify-content: space-evenly;
    line-height: 28px;
    font-size: 20px;
    font-weight: 400;
}

.world-wide-panel .top-five-panel .content .top-five-row .number {
    width: 10%;
    text-align: center;    
}

.world-wide-panel .top-five-panel .content .top-five-row .value {
    text-align: right;
    width: 50%;
}

#top-five h2 {
    text-align: center;
    width: 100%;
    font-size: 40px;
    margin: 0px;
}

@media only screen and (max-width: 1000px) {
    .world-wide-panel .top-five-panel {
        flex-basis: calc(40% - 40px);
    }
}

@media only screen and (max-width: 600px) {
    .world-wide-panel .top-five-panel {
        flex-basis: calc(80% - 40px);
    }
}
/* stat-box id */
#stat-box {
    width: 260px;
    height: 160px;
    text-align: center;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;    
    padding: 5px;
}

/* stat-box class */
.stat-box {
    margin: 20px;
    flex-basis: calc(20% - 40px);
}

#stat-box h1 {
    width: 100%;
    font-size: 24px;
    margin: 0 auto;
    display: block;
    text-align: center;
}

#stat-box h2 {
    text-align: center;
    width: 100%;
    font-size: 40px;
    margin: 0px;
}

@media only screen and (max-width: 1550px) {
    .stat-box {
        flex-basis: calc(40% - 40px);
    }
}

@media only screen and (max-width: 900px) {
    #stat-box {
        flex-basis: calc(80% - 40px);
    }
}

#podium-box {
    width: 260px;
    height: 160px;
    text-align: center;
    display: flex;
    justify-content: space-around;
}
#belt-progress-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 2%;
    width: 100%;
}

#belt-progress-container #belt {
    height: 50px;
    margin: 20px 0;
    width: 70%;
}

@media only screen and (max-width: 600px) {
    #belt-progress-container #belt {
        margin: 10px 0;
        width: 90%;
    }
}

#settings-panel form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 0px;
    min-width: 300px;
}

#settings-panel form div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px 10px;
}

#settings-panel form label {
    margin: 5px 10px;
    text-align: center;
    background: black;
    color: white;
    border-radius: 10px;
    line-height: 24px;
}

#settings-panel form input {
    margin: 10px;
    height: 20px;
    border: 1px solid lightgray;
    border-radius: 8px;
    text-align: center;
}

#settings-panel form textarea {
    margin: 10px;
    height: 200px;
    border: 1px solid lightgray;
    border-radius: 5px;
    resize: vertical;
}

#settings-panel form #error {
    color:red;
    text-align: center;
}

#settings-panel form #status {
    color:blue;
    text-align: center;
}

#settings-panel form #buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
}

#settings-panel form #buttons button {
    margin: 15px 10px;
    max-width: 200px;
}

#settings-panel form #buttons button:nth-child(2) {
    background-color: #CA0813;
}

@media only screen and (max-width: 700px) {
    #settings-panel form #buttons {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }
}



.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.drop-shadow {
  border-top: 1px solid rgba(0, 0, 0, 0.10);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
}

/* Make space for drop shadow */
.slider-slide {
  padding: 6px 0; 
}

/* Make space between carousel and controller */
.slider {
  margin: 20px 0;
}

.corner-radius {
  border-radius: 15px;
  overflow: hidden;
}
