#page > #header {
    flex-shrink: 0;
    height: 55px !important;
    width: 100%;
    background-color: black;
    display: flex;
    justify-content: space-around;
}

#page > #header .button-area {
    flex-grow: 1;
    flex-basis: 10px;
    width: 10px;
}

#page > #header #logo-area {
    display: flex;
}

#page > #header #logo-area a {
    text-decoration: none;
}

#page > #header #logo-area a #logo {
    width: 228px;
    height: 100%;
    display: flex;
    background-color: #CC0000;
}

#page > #header #logo-area a #logo .white {
    width: 6px;
    height: 100%;
    background-color: white;
}

#page > #header #logo-area a #logo #title {
    flex-grow: 1;
    text-align: center;
    color: white;
    font-size: 30px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
}

#page > #header .button-area#right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

#page > #header .button-area#right button {
    all: unset;
    margin-right: 10px;
    width: 44px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#page > #header .button-area#right button img {
    width: 100%;
}

@media only screen and (max-width: 375px) {
    #page > #header #logo-area a #logo {
        width: 200px;
    }
    #page > #header #logo-area a #logo #title {
        font-size: 30px;
        font-weight: 600;
    }
    #page > #header .button-area#right {
        justify-content: center;
    }
    #page > #header .button-area#right button {
        margin-right: 0px;
        width: 32px;
    }
}