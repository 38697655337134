#belt {
    position: relative;
    border: 1px solid black;
    border-radius: 10px;
    display: flex;
    justify-items: center;
    overflow: hidden;
}

#belt #background {
    width: 100%;
    height: 100%;
}

#belt #background #decorations {
    height: 100%;
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-between;
}

#belt #kid-stripe {
    position: absolute;
    width: 100%;
    top: 12px;
    bottom: 12px;
    z-index: 0;
}

#belt #belt-text {
    font-size: 20px;
    line-height: 50px;
    font-weight: 600;
    z-index: 1;
    text-align: center;
    flex: 1;
}

#belt #rank {
    display: flex;
    flex-direction: row-reverse;
    z-index: 1;
    width: 70px;
}

#belt #rank #stripe {
    background-color: white;
    width: 11%;
    margin-right: 11%;
    height: 100%;
    z-index: 1;
}