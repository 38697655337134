.status {
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    padding: 15px 5px;
    text-align: center;
}

.status.error {
    color: #cf000c;
}
  
.status.success {
    color: #1069c9 ;
}

.status.none {
    /* Need to reserve space now because .slider-frame is set to overflow none */
    min-height: 22px;
}