/* stat-box id */
#stat-box {
    width: 260px;
    height: 160px;
    text-align: center;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;    
    padding: 5px;
}

/* stat-box class */
.stat-box {
    margin: 20px;
    flex-basis: calc(20% - 40px);
}

#stat-box h1 {
    width: 100%;
    font-size: 24px;
    margin: 0 auto;
    display: block;
    text-align: center;
}

#stat-box h2 {
    text-align: center;
    width: 100%;
    font-size: 40px;
    margin: 0px;
}

@media only screen and (max-width: 1550px) {
    .stat-box {
        flex-basis: calc(40% - 40px);
    }
}

@media only screen and (max-width: 900px) {
    #stat-box {
        flex-basis: calc(80% - 40px);
    }
}