#page {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

#page.menu-open {
  pointer-events: none;
  user-select: none;
  -webkit-filter: blur(35px);
  -moz-filter: blur(5px);
  -ms-filter: blur(5px);
  -o-filter: blur(5px);
  filter: blur(5px);
}

#page-menu-wrapper {
  height: 100vh;
}

#page {
  display: flex;
  flex-direction: column;
  background-color: #F0F0F0;
}

#page #content {
  flex-grow: 1;
  width: 100%;
  overflow: auto;
  flex-direction: column;

  /* Fix footer to bottom of screen (for iOS devices) */
  /* position: fixed;
  top: 80px;
  bottom: 100px;
  z-index: 10; */
}

#page #page-body {
  margin: auto;
  width: 95%;
  max-width: 1300px;
  display: flex;
  flex-direction: column;
}