button {
    /* TODO: Move this */
    width: 240px;
    height: 36px;
    color: #ffffff;
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    background-color:#1069c9;
    border-radius: 8px;
    border: 0px solid black;
}

#home #content-panel #header {
    display: none;
}

#home #content-panel #body {
    border: none;
    box-shadow: none;
}

#home-page-panel {
    max-width: 280px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    margin: auto;
}

#home #content-panel #body {
    background-color: transparent;
}

#home-page-panel img {
    height: 150px;
    width: 150px;
    padding: 25px;
}

#home-page-panel .title {
    width: 100%;
    height: 40px;
    color: white;
    background-color: black;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: -1.43px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#home-page-panel .text {
    /* TODO: Change this to use flex instead of a fixed height */
    height: 140px;
    color: #000000;
    font-size: 22px;
    font-weight: 300;
    line-height: 32px;
    display: flex;
    justify-content: center;
    margin: 20px;
}

#home-page-panel button {
    text-transform: capitalize;
    margin: 20px;
    width: 80%;
    display: block;
}

@media only screen and (max-width: 375px /* iPhone X */) {
    #home-page-panel {
        /* Subtract gutter space to make room for drop shadow */
        width: calc(100% - 20px);
    }
}