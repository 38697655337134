#settings-panel form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 0px;
    min-width: 300px;
}

#settings-panel form div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px 10px;
}

#settings-panel form label {
    margin: 5px 10px;
    text-align: center;
    background: black;
    color: white;
    border-radius: 10px;
    line-height: 24px;
}

#settings-panel form input {
    margin: 10px;
    height: 20px;
    border: 1px solid lightgray;
    border-radius: 8px;
    text-align: center;
}

#settings-panel form textarea {
    margin: 10px;
    height: 200px;
    border: 1px solid lightgray;
    border-radius: 5px;
    resize: vertical;
}

#settings-panel form #error {
    color:red;
    text-align: center;
}

#settings-panel form #status {
    color:blue;
    text-align: center;
}

#settings-panel form #buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
}

#settings-panel form #buttons button {
    margin: 15px 10px;
    max-width: 200px;
}

#settings-panel form #buttons button:nth-child(2) {
    background-color: #CA0813;
}

@media only screen and (max-width: 700px) {
    #settings-panel form #buttons {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }
}