#time-duration {
    width: 100%;
    display: flex;
}

#time-duration span {
    width: 100%;
    display: flex;
    justify-content: center;
}

#text-area {
    width: 100%;
    height: 260px;
    display: flex;
    flex-direction: column;
    font-size: 25px;
    margin-top: 20px;
}

#text-area span {
    color: white;
    background-color: black;
    border-radius: 12px;
    text-align: center;
}

#text-area textarea {
    height: 100%;
    margin: 20px 0px;
    border-radius: 10px;
    border: 1px solid #C9C9C9;
    font-size: 18px;
}