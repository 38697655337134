#popup-menu .menu {
    font-weight: 600;
    border-radius: 20px;
    max-width: 300px;
}

#popup-menu .menu .menu-item {
    margin-bottom: 5px;
    text-align: center;
}

#popup-menu .menu h1 {
    font-size: 24px;
    color: #707070;
}

#popup-menu .menu a {
    color: black;
    text-decoration: none;
    font-size: 20px;
    font-weight: 400;
}