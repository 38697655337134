#content-panel {
    margin: auto;
    width: calc(100% - 6px);    /* Make room for drop shadow */
    flex: 1;
    margin-bottom: 20px;
}

#content-panel #header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: black;
    color: white;
    height: 100px;
    width: 100%;
    overflow: hidden;
}

#content-panel #header #title img {
    height: 74px;
    width: auto;
}

#content-panel #header #title {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: auto;
    height: 100%;
    font-size: 26px;
}

#content-panel #header button {
    all: unset;
    font-size: 17px;
    font-weight: 500;
    color: #1069c9;
    text-decoration: underline;
}

#content-panel #header button#left {
    margin-left: 20px;
}

#content-panel #header button#right {
    margin-right: 20px;
}

#content-panel #header button.hidden {
    visibility: hidden;
}

#content-panel #body {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background-color: white;
}

@media only screen and (max-width: 375px /* iPhone X */) {
    #content-panel {
        /* Subtract gutter space */
        width: calc(100% - 20px);
    }
    #content-panel #header #title {
        font-size: 18px;
    }
}