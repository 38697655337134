#belt-progress-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 2%;
    width: 100%;
}

#belt-progress-container #belt {
    height: 50px;
    margin: 20px 0;
    width: 70%;
}

@media only screen and (max-width: 600px) {
    #belt-progress-container #belt {
        margin: 10px 0;
        width: 90%;
    }
}