#footer {
    /* TODO: Figure out why I cannot increase height without footer moving off page  */
    background-color: black;
    height: 55px;
    width: 100%;
    font-weight: 700;
    letter-spacing: -1.43px;
    overflow: hidden;
    flex-shrink: 0;
    
    /* Fix footer to bottom of screen (for iOS devices) */
    /* position: fixed;
    bottom: 0;
    z-index: 10; */
}

#footer #container {
  width: 70%;
  max-width: 1300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  font-family: "Open Sans";
  height: 100%;
  background-color: black;
  margin: auto;
  font-size: 18px;
}

#footer #container .popup-content {
  border-radius: 10px;
}

#footer #container .menu {
  font-weight: 600;
  border-radius: 15px;
  max-width: 300px;
  padding-bottom: 10px;
}

#footer #container .menu .menu-item {
  margin-bottom: 5px;
}

#footer #container .menu h1 {
  font-size: 24px;
  color: #707070;
  text-align: center;
}

#footer #container .menu a {
  color: black;
  text-decoration: none;
  font-size: 25px;
  font-weight: 400;
}

@media only screen and (max-width: 700px) {
  #footer #container {
    flex-direction: column;
    justify-content: space-around;
  }
  #footer #container #tagline {
    display: none;
  }
}