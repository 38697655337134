#search-table #search-row .date {
    width: 140px;
}

#search-table #search-row .type {
    width: 120px;
}

#search-table #search-row .key {
    width: 30%;
}

#search-table #search-row .match {
    padding: 10px 20px; 
    font-weight: 200;
}

#search-table #search-row .menu {
    padding-right: 10px;
}

#search-table #popup-menu .popup-content .menu {
    width: 100%;
}

#search-table #popup-menu .popup-content .menu #edit {
    text-align: center;
}

#search-table #popup-menu .popup-content .menu hr {
    display: none;
}

#search-table #popup-menu .popup-content #delete {
    display: none;
}

@media only screen and (max-width: 600px) {
    #search-table #search-row .date {
        display: none;
    }
    #search-table #search-row .type {
        display: none;
    }    
    #search-table #search-row .key {
        display: none;
    }
}

@media only screen and (max-width: 1000px) {
    #search-table #search-row .type {
        display: none;
    }    
}