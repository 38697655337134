#header-stats {
    margin: 20px auto;
    width: 100%;
    display: flex;
}

#header-stats .column {
    width: 280px;
    height: 100px;
    margin: auto;
    outline: none;
    overflow: none;
}

#user-info-panel {
    display: flex;
    border-radius: 10px;
}   

#user-info-panel #left-column {
    display: flex;
    align-items: center;
    flex-grow: 0;
}

#user-info-panel #left-column #logo {
    width: auto;
}

#user-info-panel #right-column {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

#user-info-panel #right-column div + div {
    margin-top: 10px;
}

#user-info-panel #right-column #user-name {
    font-size: 25px;
    font-weight: 700;
}

#header-belt-panel #belt {
    height: 100%;
    width: 100%;
    border: none;
}

#header-belt-panel #belt #belt-background #kid-stripe {
    height: 50%;
    margin-top: auto;
    margin-bottom: auto;
}

#header-belt-panel #belt #rank {
    width: 40%;
}

#total-training-panel  {
    height: 100px;
    width: 300px;
    border-radius: 10px;
    text-align: center;
    overflow: hidden;
}

#total-training-panel #title-row {
    height: 40%;
    display: flex;
    flex-direction: column;
}

#total-training-panel #title {
    background: #cc0000;
    color: white;
    font-size: 150%;
    font-weight: 600;
    height: 100%;
    width: 100%;
    padding-top: 2px;
}

#total-training-panel #duration-value-row {
    height: 30%;
    background-color: white;
    display: flex;
    justify-content: space-around;
}

#total-training-panel #duration-value-row .duration-value {
    font-size: 150%;
    font-weight: 600;
    margin: auto;
}

#total-training-panel #duration-title-row {
    height: 30%;
    background-color: white;
    display: flex;
    justify-content: space-around;
}

#total-training-panel #duration-title-row .duration-title {
    background-color: black;
    color: white;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 600;
    width: 85px;
    height: 70%;
    height: 16px;
    line-height: 16px;
    margin: auto;
}

#header-stat-panel {
    height: 100px;
    width: 300px;
    border-radius: 10px;
    text-align: center;
    overflow: hidden;
}

#header-stat-panel #title-row {
    height: 40%;
    display: flex;
    flex-direction: column;
}

#header-stat-panel #title {
    background: black;
    color: white;
    font-size: 150%;
    font-weight: 600;
    height: 100%;
    width: 100%;
    padding-top: 2px;
}

#header-stat-panel #body-row {
    height: 60%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
#header-stat-panel #body {
    width: 100%;
    background: white;
    color: black;
    font-size: 250%;
    font-weight: 700;
    height: 100%;
    padding-top: 2px;
}

@media only screen and (max-width: 375px /* iPhone X */) {
    #header-stats {
        margin: 5px auto;
    }
    #header-stats .column {
        /* Subtract gutter space */
        width: calc(100% - 20px);
        height: 80px;
    }
    #user-info-panel #left-column #logo {
        height: 80px;
    }
}