#sidebar .section {
    padding: 0px 30px;
    display: flex;
    flex-direction: column;
    align-content: left;
    align-items: left;
}

#sidebar .section h1 {
    text-align: left;
    margin-bottom: 20px;
}

#sidebar .section .content {
    width: 100%;
    display: flex;
    align-content: center;
    align-items: center;
}

#sidebar #close-section.section {
    display: flex !important;
    flex-direction: row-reverse;
}

#sidebar #close-section #close-button {
    all: unset;
    height: 50px;
    width: 50px;
    padding: 10px 0;
}

#sidebar #search-section form {
    display: flex;
    align-items: stretch;
    padding: 10px 0;
}

#sidebar #search-section form #search-icon {
    height: 50px;
    width: 50px;
}

#sidebar #search-section form input {
    font-size: 30px;
    border-radius: 10px;
    border: none;
    width: 100%;
    margin-left: 15px;
    text-align: center;
}

#sidebar #search-section form ::placeholder {
    height: 100%;
    font-size: 26px;
    text-transform: uppercase;
    text-align: center;
    color: #c9c9c9;
}

#sidebar .section .content div img {
    margin-left: 20px;
    margin-right: 30px;
}

.section .content #menu-link {
    all: unset;
    color: white;
    -webkit-text-fill-color: white;
    font-size: 22px;
    font-weight: 400;
    text-decoration: none;
    display: block;
    padding: 5px 0px;
    cursor: default;
    text-align: left;
}

.bm-menu {
    background-color: #CC0000;
    color: white;
    margin: 0;
}

.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 15px;
    top: 25px;
}

@media only screen and (max-width: 414px) {
    #sidebar .section {
        padding: 10px 20px;
    }
    #sidebar .section .content div img {
        margin-left: 10px;
        margin-right: 20px;
    }
    .section .content #menu-link {
        padding: 5px 0px;
    }
}

@media only screen and (max-width: 320px) {
    #sidebar .section h1 {
        margin-top: 5px;
        margin-bottom: 5px;
    }
    #sidebar .section .content #menu-link {
        font-size: 18px;
    }
}